import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

export default function SEO({ children, location, description, title, image }) {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `);
  const tit = title || site.siteMetadata.title || 'Yuks';
  const desc =
    description ||
    site.siteMetadata.description ||
    'Where comedy fans can discover new comedians and learn more about their favorites.';
  const url = site.siteMetadata.siteUrl || 'https://www.yuks.co/';
  return (
    <Helmet>
      <html lang="en" />
      <title>{tit}</title>
      {/* Fav Icons */}
      <link rel="icon" href="/favicon.png" />
      {/* Meta Tags */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta charSet="utf-8" />
      <meta name="description" content={desc} />
      <link rel="canonical" href={url} />
      {/* Open Graph */}
      {location && <meta property="og:url" content={location.href} />}
      <meta property="og:image" content={image || '/favicon.png'} />
      <meta property="og:title" content={tit} key="ogtitle" />
      <meta property="og:site_name" content={tit} key="ogsitename" />
      <meta property="og:description" content={desc} key="ogdesc" />
      {children}
    </Helmet>
  );
}
